<template>
  <v-container fluid id="container">
    <v-row
      class="text-subtitle-1 mt-8 mt-md-16 pt-md-4 ml-6"
      justify="start"
      align="center"
    >
      <v-icon color="blue">mdi-chevron-left</v-icon>
      <router-link
        to="/signup"
        class="text-decoration-none font-weight-bold fs-14 ml-4 mb-0"
        id="sign-up-link"
      >
        {{ $t("backToSignUp") }}
      </router-link>
    </v-row>
    <v-row class="d-flex align-center">
      <v-col offset="1" offset-md="2" cols="10" md="8">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <v-form role="loginForm" @submit.prevent="handleSubmit(sendLink)">
            <v-row class="text-h4 font-weight-bold mb-8 mt-16" justify="center">
              {{ $t("confirmEmailHeader") }}
            </v-row>
            <v-row
              class="text-subtitle-1 text-md-h6 font-weight-regular mt-8"
              justify="center"
            >
            {{ $t("confirmEmailSuccess.part1") }} <br />
              <span>
                <b>{{ email }}</b>. {{ $t("confirmEmailSuccess.part2") }} <br />
                {{ $t("confirmEmailSuccess.part3") }}
              </span>
            </v-row>
            <v-row class="text-subtitle-1 mt-16" justify="center">
              <v-col cols="12" class="d-flex align-center justify-center">
                {{ $t("doNotGetEmail") }}
                <v-btn
                  type="submit"
                  color="primary"
                  plain
                  id="resend-email-link"
                >
                  {{ $t("resendEmail") }}
                </v-btn>
            </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import makeAuthService from '@/services/api/auth';
export default {
  data() {
    return {
      isLoading: false,
      email: ''
    };
  },
  mounted() {
    this.email = this.$route.query.email;
  },
  methods: {
    sendLink() {
      this.isLoading = true;
      const authService = makeAuthService(this.$api);
      authService.forgotPassword({ email: this.email }).then(
        response => {
          if (response.status === 200) {
            this.$swal({
              icon: 'success',
              title: this.$t('password reset email sent'),
              timer: '2000',
            });
            this.isLoading = false;
          }
        },
        error => {
          this.isLoading = false;
          this.$swal({
            icon: 'error',
            title: this.$t('error'),
            text: error.response.data.error,
          });
        }
      );
    },
  },
};
</script>
